import { observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import RenderOnQueries from '../RenderOnQueries'
import SessionStore from '../../State/SessionStore'
import { qf } from '../../Queries/queryFormatter'
import TimesheetAppStore from './TimesheetAppStore'
import { format, isSameDay } from 'date-fns'
import classNames from 'classnames'
import { formatMinutes } from '../../utils'
import TimesheetList from './TimesheetList'
import _ from 'lodash'
import TimeEntryCollection from '../../State/Collections/TimeEntryCollection'
import LoadingSpinner from '../../Components/LoadingSpinner'
import { getPreviousDayTime } from '../../Queries/previousTime'
import { usingNewLogin } from '../../State/Permissions/HasPermissions'
import { SignOutButton } from '@clerk/clerk-react'

export default observer(() => {
    const today = new Date()
    return (
        <RenderOnQueries
            loading={<LoadingSpinner />}
            queryIds={[
                {
                    collection: 'timeEntries',
                    fields: [
                        'costCentreId',
                        'projectId',
                        'phaseId',
                        'staffId',
                        'taskId',
                        'startMinutes',
                        'numMinutes',
                        'date',
                        'notes',
                        'isVariation',
                        'isBillable',
                        'isOvertime',
                        'isLocked',
                        'deletedAt',
                    ],
                    filters: [
                        `staffId == "${SessionStore.userId}"`,
                        `date >= ${qf(TimesheetAppStore.startOfWeek)}`,
                        `date <= ${qf(TimesheetAppStore.endOfWeek)}`,
                    ],
                    chain: [
                        {
                            collection: 'phases',
                            join: { timeEntries: 'phaseId', phases: 'id' },
                            fields: [
                                'jobNumber',
                                'name',
                                'projectId',
                                'isRootPhase',
                            ],
                        },
                        {
                            collection: 'tasks',
                            join: { timeEntries: 'taskId', tasks: 'id' },
                            fields: ['name', 'phaseId', 'projectId'],
                        },
                    ],
                },
            ]}
        >
            <div>
                {SessionStore.updateAvailable ? (
                    <div
                        style={{
                            padding: '7px 0',
                            position: 'fixed',
                            top: '0',
                            zIndex: '1000',
                            background: '#0000FF',
                            width: '100%',
                            height: '32px',
                            fontSize: '0.9em',
                            textAlign: 'center',
                            fontWeight: '600',
                            color: '#FFF',
                            boxSizing: 'border-box',
                        }}
                        onClick={() => {
                            let url = window.location.href
                            url += `${
                                url.indexOf('?') > -1 ? '&' : '?'
                            }ts=${Date.now()}`
                            window.location.href = url
                        }}
                    >
                        Click Here to Update Coincraft.
                    </div>
                ) : null}
                <div className="top-nav">
                    <div
                        className="nav-btn left"
                        onClick={() => TimesheetAppStore.shiftWeek(-1)}
                    >
                        <i className="fa fa-chevron-left" />
                    </div>
                    <div className="nav-title">
                        <span className="date-title">
                            <span style={{ fontSize: '0.9em' }}>
                                Week Starting
                            </span>
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                {format(
                                    TimesheetAppStore.startOfWeek,
                                    'EEE, d MMM yyyy'
                                )}
                            </span>
                        </span>
                    </div>
                    <div
                        className="nav-btn right"
                        onClick={() => TimesheetAppStore.shiftWeek(1)}
                    >
                        <i className="fa fa-chevron-right" />
                    </div>
                </div>
                <div className="weekday">
                    {TimesheetAppStore.daysOfWeek.map(function (date, i) {
                        return (
                            <div
                                key={format(date, 'yyyy-MM-dd')}
                                className="d-sel"
                                onClick={() =>
                                    TimesheetAppStore.selectDate(date)
                                }
                            >
                                <div
                                    className={classNames('day', {
                                        selected: isSameDay(
                                            date,
                                            TimesheetAppStore.selectedDate
                                        ),
                                        today: isSameDay(date, today),
                                    })}
                                >
                                    <div className="day-circle">
                                        <span className="mini-day">
                                            {format(date, 'EEE')}
                                        </span>
                                        <span className="mini-date">
                                            {' '}
                                            {format(date, 'dd')}
                                        </span>
                                    </div>
                                </div>
                                <div className="day-hours">
                                    {formatMinutes(
                                        _.sum(
                                            (
                                                TimeEntryCollection
                                                    .timeEntriesByDate[
                                                    date.getTime()
                                                ] || []
                                            ).map((te) => te.numMinutes)
                                        )
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <TimesheetList />
                <div
                    className="project-btn"
                    onClick={async () => {
                        const uniqueRows = await getPreviousDayTime(
                            SessionStore.userId,
                            TimesheetAppStore.selectedDate
                        )
                        uniqueRows.forEach((r) => {
                            TimeEntryCollection.add(
                                {
                                    numMinutes: 0,
                                    ...r,
                                    date: TimesheetAppStore.selectedDate,
                                    staffId: SessionStore.userId,
                                },
                                { trackUpdates: true }
                            )
                        })
                    }}
                >
                    + Copy Previous Entries
                </div>
                <div style={{ borderBottom: 'solid 1px #ccc' }}>
                    <div
                        className="add-entry-button project-btn"
                        onClick={() =>
                            TimesheetAppStore.setRoute('selectCostCentre')
                        }
                    >
                        + Add Entry
                    </div>
                </div>
                <div style={{ textAlign: 'center', marginTop: '1em' }}>
                    <img
                        src={
                            new URL(
                                '/public/coincraft_logo_130.png',
                                import.meta.url
                            )
                        }
                        alt="Coincraft Logo"
                    />
                </div>
                <div>
                    {usingNewLogin() ? (
                        <SignOutButton redirectUrl="/">
                            <div className="add-entry-button project-btn">
                                Logout
                            </div>
                        </SignOutButton>
                    ) : (
                        <div
                            className="add-entry-button project-btn"
                            onClick={async () => {
                                await SessionStore.logout()
                            }}
                        >
                            Logout
                        </div>
                    )}
                </div>
            </div>
        </RenderOnQueries>
    )
})
